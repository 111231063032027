import { PAGE_NAME } from "../constants/htmlStrings";
import { PARTNER_EXPERIMENT } from "./experimentConstants";

const IOS_APP_STORE_LINK =
  "https://apps.apple.com/in/app/buystars/id1620171125";

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
export const isAppleDevice = /(iPhone|iPad|iPod)/i.test(navigator.userAgent);

export function downloadApk(
  pageName = PAGE_NAME.HOME,
  setIsLoading = () => {},
  setProgress = () => {}
) {
  const shouldDoPercentage2 = true;
  const percent10 = Date.now() % 10;
  const isNewVersion = percent10 !== 0;
  let reminderPercent3 = Date.now() % 3;
  if (isAppleDevice || isSafari)
    return process.env.IOS_APP_STORE_LINK || IOS_APP_STORE_LINK;

  let apkUrl = process.env.REACT_APP_APK_PATH_LP2;
  let utmAppendKey = "";
  let branchLink = "";
  utmAppendKey = window.location.search === "" ? "?" : "&";

  if (pageName === PAGE_NAME.LP5) {
    utmAppendKey += "utm_ab=trump-beta";
    apkUrl = process.env.REACT_APP_APK_PATH_TRUMP_BETA;
    branchLink = process.env.REACT_APP_BRANCH_LP1_LINK_BETA;
  } else if (pageName === PAGE_NAME.LP3) {
    utmAppendKey += "utm_ab=reactivation";
    apkUrl = process.env.REACT_APP_APK_PATH_LP2;
    branchLink = process.env.REACT_APP_BRANCH_LINK_LP3;
  } else if (pageName === PAGE_NAME.LP6) {
    utmAppendKey += "utm_ab=reactivation";
    apkUrl = process.env.REACT_APP_APK_PATH_LP2;
    branchLink = process.env.REACT_APP_BRANCH_LINK_LP6;
  }else if (pageName === PAGE_NAME.LP7) {
    utmAppendKey += "utm_ab=reactivation";
    apkUrl = process.env.REACT_APP_APK_PATH_LP2;
    branchLink = process.env.REACT_APP_BRANCH_LINK_LP7;
  } else if (shouldDoPercentage2) {
    switch (isNewVersion) {
      case null:
        utmAppendKey += "utm_ab=trump-beta";
        apkUrl = process.env.REACT_APP_APK_PATH_TRUMP_BETA;
        branchLink = process.env.REACT_APP_BRANCH_LP1_LINK_BETA;
        break;
      case false:
        utmAppendKey += "utm_ab=previous-version";
        apkUrl = process.env.REACT_APP_APK_PATH_LP2_PREVIOUS;
        branchLink = process.env.REACT_APP_BRANCH_LP1_LINK_PREVIOUS;
        break;

      case true:
        utmAppendKey += "utm_ab=new-version";
        apkUrl = process.env.REACT_APP_APK_PATH_LP2;
        branchLink = process.env.REACT_APP_BRANCH_LP1_LINK;
        break;

      default:
        utmAppendKey += "utm_ab=previous-version";
        apkUrl = process.env.REACT_APP_APK_PATH_LP2_PREVIOUS;
        branchLink = process.env.REACT_APP_BRANCH_LP1_LINK_PREVIOUS;
    }
  } else {
    switch (reminderPercent3) {
      case 0:
        utmAppendKey += "utm_ab=previous-version";
        apkUrl = process.env.REACT_APP_APK_PATH_LP2_PREVIOUS;
        branchLink = process.env.REACT_APP_BRANCH_LP1_LINK_PREVIOUS;
        break;
      case 1:
        utmAppendKey += "utm_ab=new-version";
        apkUrl = process.env.REACT_APP_APK_PATH_LP2;
        branchLink = process.env.REACT_APP_BRANCH_LP1_LINK;
        break;
      case 2:
        utmAppendKey += "utm_ab=trump-beta";
        apkUrl = process.env.REACT_APP_APK_PATH_TRUMP_BETA;
        branchLink = process.env.REACT_APP_BRANCH_LP1_LINK_BETA;
        break;
      default:
        utmAppendKey += "utm_ab=previous-version";
        apkUrl = process.env.REACT_APP_APK_PATH_LP2_PREVIOUS;
        branchLink = process.env.REACT_APP_BRANCH_LP1_LINK_PREVIOUS;
    }
  }

  if (
    pageName === PAGE_NAME.HOME ||
    pageName === PAGE_NAME.LP1 ||
    pageName === PAGE_NAME.LP3 ||
    pageName === PAGE_NAME.LP5 ||
    pageName === PAGE_NAME.LP6 ||
    pageName === PAGE_NAME.LP7
  ) {
    return `${branchLink}${window.location.search}${utmAppendKey}`;
  } else {
    var request = new XMLHttpRequest();
    request.responseType = "blob";
    request.open("get", apkUrl);
    request.send();
    setIsLoading(true);

    request.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        const imageURL = window.URL.createObjectURL(this.response);
        const anchor = document.createElement("a");
        anchor.href = imageURL;
        anchor.download = "BuystarsProTF.apk";
        document.body.appendChild(anchor);
        anchor.click();
        setIsLoading(false);
        fetch(
          `${process.env.REACT_APP_BRANCH_LP2_LINK}${window.location.search}${utmAppendKey}`
        );
      }
    };

    request.onprogress = function (e) {
      const percent_complete = Math.floor((e.loaded / e.total) * 100);
      setProgress(percent_complete);
    };
    return null;
  }
}

export function downloadApkForExperiment(experimentId) {
  let utmAppendKey = "";
  let branchLink = "";

  console.log("experimentId", experimentId);
  utmAppendKey = window.location.search === "" ? "?" : "&";
  switch (experimentId) {
    case PARTNER_EXPERIMENT.NONE:
      utmAppendKey += "utm_ab=partner_none";
      branchLink = process.env.REACT_APP_BRANCH_LINK_PARTNER_EXPERIMENT_NONE;
      break;

    case PARTNER_EXPERIMENT.KKR:
      utmAppendKey += "utm_ab=partner_kkr";
      branchLink = process.env.REACT_APP_BRANCH_LINK_PARTNER_EXPERIMENT_KKR;
      break;

    case PARTNER_EXPERIMENT.RCB:
      utmAppendKey += "utm_ab=partner_rcb";
      branchLink = process.env.REACT_APP_BRANCH_LINK_PARTNER_EXPERIMENT_RCB;
      break;

    case PARTNER_EXPERIMENT.BOTH:
      utmAppendKey += "utm_ab=partner_both";
      branchLink = process.env.REACT_APP_BRANCH_LINK_PARTNER_EXPERIMENT_BOTH;
      break;

    default:
      utmAppendKey += "utm_ab=partner_both";
      branchLink = process.env.REACT_APP_BRANCH_LINK_PARTNER_EXPERIMENT_BOTH;
  }

  return `${branchLink}${window.location.search}${utmAppendKey}`;
}
